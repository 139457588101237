/*
Template Name: Skote - Admin & Dashboard Template
Author: Themesbrand
Version: 4.0.0.
Website: https://themesbrand.com/
Contact: themesbrand@gmail.com
File: Main Css File
*/

//Fonts
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/mixins.scss";
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "theme-light";
@import "theme-dark";

// Structure
@import "custom/structure/general";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/vertical";
@import "custom/structure/horizontal-nav";
@import "custom/structure/layouts";

// Components
@import "custom/components/waves";
@import "custom/components/avatar";
@import "custom/components/print";
@import "custom/components/badge";

// Plugins
@import "custom/plugins/custom-scrollbar";
@import "custom/plugins/toastr";
@import "custom/plugins/switch";
@import "custom/plugins/apexcharts";
@import "custom/plugins/echarts";
@import "custom/plugins/sparkline-chart";
// Pages
@import "custom/pages/authentication";
@import "custom/pages/contacts";


div:where(.swal2-container) button:where(.swal2-styled){
    padding: 0.225em 1.1em !important;
}
.App-header {
    background: #e28282; /* Replace with your desired color */
}
.tox-tinymce {
    border: 3px solid #ced4da !important;
}

.cursor-auto-imp {
    cursor: default !important;
}